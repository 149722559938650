<template>
  <section>
    <b-row class="mb-1">
      <b-col
        cols="12"
        class="px-1"
      >
        <b-card>
          <h4>Novo perfil</h4>
          <validation-observer ref="profileValidation">
            <b-form
              class="form-validate"
            >
              <b-form-group
                label="Dê um nome ao perfil"
                label-for="profileName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nome de perfil"
                  rules="required"
                >
                  <b-form-input
                    v-model="profileName"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                    placeholder="Ex. Marketing"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <!-- PERMISSION TABLE -->
        <b-card
          no-body
          class="border mt-1"
        >
          <b-row>
            <b-col
              cols="12"
              class="px-3 py-1 d-flex align-items-center justify-content-between"
            >
              <h4>Escolhas as funcionalidades</h4>
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                <span class="align-middle">Criar perfil</span>
                <feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">Permissões</span>
                </b-card-title>
              </b-card-header>
              <b-overlay
                :show="isAPICallInProgress"
                rounded="sm"
              >
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="permissionsData"
                  :fields="tableColumns"
                  :sort-desc.sync="sortDesc"
                  :sort-by.sync="sortBy"
                >
                  <template
                    #cell(featureName)="data"
                  >
                    {{ data.value }}
                  </template>
                  <template #cell(edit)="data">
                    <b-form-checkbox
                      :checked="data.value"
                      @change="handleUpdatePermitions($event, data)"
                    />
                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      :checked="data.value"
                      @change="handleUpdatePermitions($event, data)"
                    />
                  </template>
                </b-table>
              </b-overlay>
            </b-col>
          </b-row>
          <!--  -->
        </b-card>
      </b-col>
    </b-row>

  </section>

</template>

<script>
import { mapGetters } from 'vuex'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref } from '@vue/composition-api'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BTable, BCardTitle, BCardHeader, BFormCheckbox, BButton, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'NewProfile',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BCardTitle,
    BCardHeader,
    BFormCheckbox,
    BButton,
    BOverlay,
  },
  data() {
    return {
      profileName: '',
      sortDesc: false,
      sortBy: 'featureName',
      required,
    }
  },
  mounted() {
    localize('pt_BR')
    this.getListFeatures()
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  setup() {
    const permissionsData = ref([])
    const tableColumns = [
      { key: 'featureName', label: 'Funcionalidade', sortable: false },
      { key: 'edit', label: 'Editar', sortable: false },
      { key: 'read', label: 'Ver', sortable: false },
    ]

    const prop = ref(permissionsData)

    const permissionsChecked = ref([])

    function handleUpdatePermitions(event, dataPermitions) {
      const permitionObject = {
        id: dataPermitions.item.id,
        featureName: dataPermitions.item.featureName,
        featureAlias: dataPermitions.item.featureAlias,
        edit: false,
        read: false,
      }

      const permitionTemp = prop.value.filter(permition => permition.featureName === dataPermitions.item.featureName)[0]
      const permitionTempDiff = prop.value.filter(permition => permition.featureName !== dataPermitions.item.featureName)

      const newValue = event
      const canChangeIndividual = (dataPermitions.field.key === 'read' && newValue) || (dataPermitions.field.key === 'edit' && !newValue)
      if (permitionTemp) {
        if (canChangeIndividual) {
          permitionTemp[dataPermitions.field.key] = newValue
          prop.value = [...permitionTempDiff, permitionTemp]
        } else {
          permitionTemp.edit = newValue
          permitionTemp.read = newValue
        }
      } else {
        permitionObject[dataPermitions.field.key] = newValue
        prop.value.push(permitionObject)
      }
    }

    // fetch lista de profiles
    function getListFeatures() {
      return new Promise((resolve, reject) => {
        useJwt.getListFeatures()
          .then(response => {
            this.permissionsData = response.data
            return resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ocorreu um erro ao buscar a lista de permissões. Atualize a página e tente novamente.',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    }

    return {
      permissionsData,
      prop,
      permissionsChecked,
      handleUpdatePermitions,
      tableColumns,
      getListFeatures,
    }
  },
  methods: {

    verifyCheckedPermissions(permissionObject) {
      if (permissionObject.edit === true || permissionObject.read === true) {
        this.permissionsChecked.push(permissionObject)
      }
    },
    validationForm() {
      this.$refs.profileValidation.validate().then(success => {
        if (success) {
          this.permissionsData.filter(this.verifyCheckedPermissions)

          if (this.permissionsChecked.length >= 1) {
            this.formSubmitted()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opss...',
                text: 'Você deve adicionar pelo menos uma funcionalidade para o perfil.',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    formSubmitted() {
      useJwt.createProfile({
        name: this.profileName,
        featuresProfiles: this.prop,
      })
        .then(response => {
          this.$router.push({ name: 'profiles-list' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Perfil Criado com sucesso!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          return response
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ocorreu um erro ao tentar criar o perfil. Tente novamente',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            },
          })
          return error
        })
    },
  },
}
</script>
